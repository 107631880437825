<template>
  <el-form :model="formData" :rules="rules" ref="formRef" label-width="120px">
    <el-form-item
      v-for="(item, index) in formData.labelNameList"
      :key="index"
      label="标签名称"
      class="label-item"
      :prop="'labelNameList.' + index"
      :rules="[
        { validator: validateLabelName, trigger: 'blur' },
        { required: true, trigger: 'blur' },
      ]"
    >
      <el-input
        size="small"
        :disabled="disabled"
        placeholder="请输入标签名称"
        v-model="item.labelName"
        class="input-with-select"
      >
        <el-select
          filterable
          size="small"
          slot="prepend"
          :disabled="disabled || index === 0"
          v-model="item.languageId"
          placeholder="请选择语言"
          ><el-option
            :disabled="selectLanguageIdList.includes(item.languageId)"
            v-for="item in languageList"
            :key="item.languageId"
            :value="item.languageId"
            :label="item.languageName"
          ></el-option
        ></el-select>
      </el-input>
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="small"
        v-if="index == formData.labelNameList.length - 1"
        :disabled="disabled"
        @click="handleAddItem"
      ></el-button>
      <el-button
        v-if="index !== 0"
        type="danger"
        icon="el-icon-delete"
        size="small"
        :disabled="disabled"
        @click="handleDeleteItem(index)"
      ></el-button>
    </el-form-item>
    <el-form-item>
      <el-button
        size="small"
        type="primary"
        @click="handleSubmit()"
        :disabled="disabled"
        >立即提交</el-button
      >
      <el-button size="small" @click="handleCancel()">返回</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import { ZHID } from "@/common/constant/constant.js";
export default {
  props: {
    detailData: {
      type: Object,
      default() {
        return {};
      },
    },
    languageList: {
      type: Array,
      default() {
        return [];
      },
    },
    operationType: { default: "" },
  },
  created() {
    this.initData(this.detailData);
  },
  data() {
    return {
      formData: {},
      rules: {},
    };
  },
  computed: {
    disabled() {
      return this.operationType === "view";
    },
    selectLanguageIdList() {
      return this.formData.labelNameList.map((i) => i.languageId);
    },
  },
  methods: {
    handleSubmit() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.$emit("submit", this.formData);
        }
      });
    },
    handleCancel() {
      this.$emit("cancel");
    },
    handleAddItem() {
      this.formData.labelNameList.push({ labelName: "", languageId: "" });
    },
    handleDeleteItem(index) {
      this.formData.labelNameList.splice(index, 1);
    },
    // 其他
    initData(data) {
      const formData = {
        language: 1,
        labelNameList: [{ labelName: "", languageId: ZHID }],
        ...data,
      };
      this.formData = formData;
    },
    formSumbitData() {
      const { labelId, labelNameList } = this.formData;
      // 处理语言列表数据
      const data = {
        labelId,
        labelNameList,
      };
      return data;
    },
    validateLabelName(rule, value, callback) {
      if (value.languageId === ZHID && value.labelName === "") {
        callback(new Error("请输入标签名称"));
      } else if (value.languageId === "" || value.labelName === "") {
        callback(new Error("请选择语言并输入标签名称"));
      } else {
        callback();
      }
    },
  },
  watch: {
    detailData(data) {
      this.initData(data);
      if (this.operationType === "create") {
        this.$refs.formRef.resetFields();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.input-with-select {
  vertical-align: middle;
  margin-right: 10px;
  /deep/.el-input-group__prepend {
    background: #fff;
    width: 100px;
  }
}
.el-input {
  width: 500px;
}
.label-item {
  /deep/.el-form-item__error {
    padding-left: 140px;
  }
}
</style>
